import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./WorkCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import EventHongkongFilm from "../../Assets/work/EventHongFilm.jpg";
import Deadpool from "../../Assets/work/Deadpool.jpg";
import suicide from "../../Assets/Projects/suicide.png";
import Escape from "../../Assets/work/Escape.jpg";
import Documentry from "../../Assets/work/documentry.png";
import Interview1 from "../../Assets/work/interview1.png";
import ShortFilm1 from "../../Assets/work/short film.jpg";
import mvKlachBatOun from "../../Assets/work/musicMV_klachBatOun.png";
import mvMemory from "../../Assets/work/MvMemory.png";
import mvLbech from "../../Assets/work/MvLbech.png";
import mvDepression from "../../Assets/work/mvDepression.jpg";


function WorkNew() {
  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">
          <strong className="rowdies-bold"> My Recent Works </strong>
        </h1>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Deadpool}
              isBlog={false}
              title="Pre-Screening"
              description="Movie name: Deadpool & Wolverine"
              // demoLink="https://www.facebook.com/share/v/pjdYVqjdfiSzrbUz/?mibextid=WC7FNe"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Escape}
              isBlog={false}
              title="Pre-Screening"
              description="Moive name: Escape"
              // demoLink="https://www.facebook.com/share/v/5HcziU6EXmSmip9s/?mibextid=WC7FNe"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EventHongkongFilm}
              isBlog={false}
              title="Event"
              description="Hong Kong Film Gala Presentation"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Documentry}
              isBlog={false}
              title="Documentary"
              description="ជា យុទ្ធា"
              demoLink="https://www.facebook.com/share/v/o99Rngr9Xek17ujU/?mibextid=WC7FNe"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Interview1}
              isBlog={false}
              title="Interview"
              description="កែវភ្នែកនាង"
              demoLink="https://web.facebook.com/watch/?mibextid=WC7FNe&v=1025022768464748&rdid=viIMAbNVxeHWLsGJ"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ShortFilm1}
              isBlog={false}
              title="Cinematographer"
              description="Pending....."
              // demoLink="https://web.facebook.com/watch/?mibextid=WC7FNe&v=1025022768464748&rdid=viIMAbNVxeHWLsGJ"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mvKlachBatOun}
              isBlog={false}
              title="Music Video"
              description="ខ្លាចបាត់បង់អូន"
              demoLink="https://www.youtube.com/watch?v=5xbrXVztnTk"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mvMemory}
              isBlog={false}
              title="Music Video"
              description="ការចងចាំ"
              demoLink="https://www.youtube.com/watch?v=Ql0-3CknT5Q"
            />
          </Col> */}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mvLbech}
              isBlog={false}
              title="Music Video"
              description="ល្បិច"
              demoLink="https://www.youtube.com/watch?v=wh9j15Dlz9c"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mvDepression}
              isBlog={false}
              title="Music Video"
              description="សម្ពាធ"
              demoLink="https://www.youtube.com/watch?v=clIRewNpXc4"
            />
          </Col> */}

          

        </Row>
      </Container>
    </Container>
  );
}

export default WorkNew;
